import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../configuration'

export default function useTranslate() {

    const [words, setWords] = useState()

    const language = localStorage.getItem('lang')

    useEffect(() => {
        !words && axios.post(API_BASE_URL + 'api/v1/getTranslationText').then(res => {
            setWords(res.data.data.reduce((a, c) => ({ ...a, [c.title.toLowerCase() + '_ar']: c.arabic_text, [c.title.toLowerCase() + '_en']: c.english_text }), {}))
        })
    }, [])

    // const [inAction, setInAction] = useState([])

    function keyGetter() {
        return language === 'ar' ? '_ar' : '_en'
    }

    function t(text) {
        // console.log(text, words !== undefined && text !== '' && !text?.includes('undefined'))
        // text.toLowerCase() === 'other allowance ( aed )' && console.log(text, WORDS?.[text?.toLowerCase() + keyGetter()], text?.toLowerCase() + keyGetter(), inAction)
        if (words !== undefined && text !== '' && !text?.includes('undefined')) {
            text = String(text)
            let word = words?.[text.toLowerCase() + keyGetter()] || text
            // if (WORDS?.[text?.toLowerCase() + keyGetter()] === undefined && AR_WORDS?.[text.toLowerCase()] === undefined && !exceptWords.includes(text)) {
            //     if (!inAction.includes(text.toLowerCase())) {
            //         // console.log('New word :-', text)
            //         // dispatch(updateRedux({ key: 'useTranslateInAction', value: [...inAction, text.toLowerCase()] }))
            //         // addWordHandler(text)
            //     }
            // }
            return word
        }
        return text
    }

    return { t, _lang: keyGetter(), lang: language }
}