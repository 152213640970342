import React from 'react'
import './Loader.scss'

function Loader() {
  return (
    <div className="fixed__loader">
      <div className="colorful"></div>
    </div>
  )
}

export default Loader