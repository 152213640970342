// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDWL-fKenXMyXO7qinGa8XU5Yv7qz4n-Uc",
  authDomain: "alameen-419106.firebaseapp.com",
  projectId: "alameen-419106",
  storageBucket: "alameen-419106.appspot.com",
  messagingSenderId: "21494918911",
  appId: "1:21494918911:web:1eab53de37ae47280166ca",
  measurementId: "G-MLYRWJHYN5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app as default };
