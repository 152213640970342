import React, { useEffect, useState } from 'react'
export default function ImageComponent({ src, className }) {

    return (
        <>
            <img className={className ? className : ''} src={src} style={{animation: 'fadeIn 1s'}}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/assets/img/light-about.svg';
                
            }}
             />
        </>
    )
}
