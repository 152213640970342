import React from 'react'
import { Helmet } from 'react-helmet-async'
import { API_IMAGE_URL } from '../../configuration'

export default function MetaHead({ title, desc, keyword }) {
    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta name="description" content={desc} />
            <meta name="keywords" content={keyword} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content={API_IMAGE_URL+'/assets/img/light-about.svg'} />
            <meta property="og:url" content={API_IMAGE_URL} />
        </Helmet>
    )
}
