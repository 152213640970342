import React, { useEffect, useRef, useState } from 'react'
import Title from '../components/Title/Title'
import moment from 'moment';
import { getReports } from '../actions'
import { useTranslation } from 'react-i18next';
import useTranslate from '../components/useTranslate';
import NoData from '../components/NoData/NoData';
import MetaHead from '../components/MetaHead/MetaHead';

export default function Reports() {
    const [reports, setReports] = useState()
    const hasRendered = useRef()
    const [user, setUser] = useState()
    const { i18n } = useTranslation();
    const { t } = useTranslate();

    const storedUser = localStorage.getItem('user');
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const csrfToken = localStorage.getItem('csrfToken');
let details_load=true;
    useEffect(() => {
        setUser(storedUser ? JSON.parse(storedUser) : {});
    }, []);

    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    useEffect(() => {
        if (userId &&details_load&&user?.email) {
            details_load=false;
            const data = {
                social_id: userId,
                email : user?.email
            }
            if (!hasRendered.current) {
                getReports(data, (res) => {
                    setReports(res.data)
                })
    
                hasRendered.current = true;
            }
           
        }
    }, [user])

    return (
       <>
       {
         reports?.length > 0 ?
         <section className='alameen__reports'>
             <div className="container">
                 <div className="row justify-content-center">

                     <div className="col-md-11">
                         <Title title={'My Reports'} />
                         {
                             reports?.map((ele, i) => {
                                 return (
                                     <div className="reportGrid" key={i} data-aos="fade-up">
                                         <h6>{t('Reference ID')} : {ele?.ticket_no}</h6>
                                         <h2>{langSwitch ? ele?.incident_type?.incident_type_en : ele?.incident_type?.incident_type_ar}</h2>
                                         <span>{moment(ele?.created_date).format('MMMM DD, yyyy')}</span>
                                     </div>
                                 )
                             })
                         }
                     </div>
                 </div>
             </div>
         </section>
         :
        <NoData/>
       }
       </>


    )
}
