import React, { useEffect, useRef, useState } from 'react'
import { Message, Uploader } from 'rsuite'
import { addVolunteer, fileUploader, getCountry } from '../../actions'
import { useTranslation } from 'react-i18next';
import { Alert, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select'

// FIRBASE
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../firebase';
import { FLAG_BASE_URL } from '../../configuration';
import useTranslate from '../useTranslate';
export default function VolunteerWithUs({ setVolunteerShow }) {
    const { i18n } = useTranslation();
    const { t } = useTranslate()
    const hasRendered = useRef(false);
    const [inputs, setInputs] = useState({})
    const [user, setUser] = useState()
    const [emiratesID, setEmiratesId] = useState([]);
    const [supportingFiles, setSupportingFiles] = useState([]);
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [flagImage, setFlagImage] = useState('')
    const [countries, setCountries] = useState([])
    const [volunteer_files_Id, setVolunteerFilesID] = useState([])
    const [supportingLoading, setSupportingLoading] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);

    // GET EMAIL
    const storedUser = localStorage.getItem('user');
    const randomTrackID = localStorage.getItem('trackID');
    const token = localStorage.getItem('token');
    let deviceId = localStorage.getItem('device_id');
    const ipAddress = localStorage.getItem('ip')
    const csrfToken = localStorage.getItem('csrfToken');
    useEffect(() => {
        setUser(storedUser ? JSON.parse(storedUser) : {});
    }, []);

    // RANDOM TRACKID

    function generateTrackID(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let trackID = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            trackID += characters.charAt(randomIndex);
        }

        localStorage.setItem('trackID', trackID);
        return trackID;
    }






    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    function changeHandler(field, value) {
        setErrors(s => ({ ...s, [field]: '' }))
        setInputs(s => ({ ...s, [field]: value }))
    }


    useEffect(() => {
        if (!hasRendered.current) {
            setupRecaptcha()
            hasRendered.current = true;
        }
        generateTrackID(8);
    }, []);


    const setupRecaptcha = () => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'VolunteerCaptcha', {
                'size': 'large',
                'theme': 'light',
                'callback': (response) => {
                    setRecaptchaVerified(true);
                },
                'expired-callback': () => {
                    setRecaptchaVerified(false);
                }
            });

            window.recaptchaVerifier.render().then(() => {
                console.log('reCAPTCHA rendered');
            }).catch((error) => {
                console.error('Error rendering reCAPTCHA:', error);
            });
        } catch (error) {
            console.error('Error setting up reCAPTCHA:', error);
        }
    };

    // END

    const handlbeforeEmiratedID = (newFileList, fileType) => {
        const allowedTypes = ['application/pdf'];
        let isFileValidator = true
        let totalSize = 0
        newFileList?.forEach(file => {
            totalSize = totalSize + file.blobFile.size
            if (!allowedTypes?.includes(file?.blobFile?.type)) {
                isFileValidator = false;
            }
        })
        if ((totalSize / 1000000) > 200) {
            setErrors(s => ({ ...s, [fileType]: 'You cannot upload more than 200 MB' }))
            return false
        } else {
            setErrors(s => ({ ...s, [fileType]: '' }))
        }
        if (isFileValidator) {
            return true;
        } else {
            toast.error('Invalid file format. Only PDF file is supported.')
            return false;
        }
    }

    const handlbeforeUpload = (newFileList, fileType) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        let totalSize = 0
        let isFileValidator = true
        newFileList?.forEach(file => {
            totalSize = totalSize + file.blobFile.size
            console.log(file?.blobFile?.type);
            if (!allowedTypes?.includes(file?.blobFile?.type)) {
                isFileValidator = false;
            }
        })
        if ((totalSize / 1000000) > 200) {
            setErrors(s => ({ ...s, [fileType]: 'You cannot upload more than 200 MB' }))
            return false
        } else {
            setErrors(s => ({ ...s, [fileType]: '' }))
        }
        if (isFileValidator) {
            return true;
        } else {
            toast.error('Invalid file format. Only images and PDF files are supported.')
            return false;
        }
    }

    const handleRemove = async (file) => {
        //alert("something new")
        let existing_data = await volunteer_files_Id.filter(item => item !== file.file_id);
        setVolunteerFilesID(existing_data);
        return false
    };

    const handleChange = (newFileList, fileType) => {
        let keys = []

        // const hasNewFiles = newFileList.some(file => !file?.isprocessed); 
        const newFiles = newFileList.filter(file => {
            return !file?.isprocessed
        });
        if (newFiles.length == 0) {
            setLoading(false)
            return
        }

        if (fileType == 'emiratesId') {
            let file = newFiles[0]

            if (file) {
                setLoading(true)
                const formData = new FormData();
                formData.append('track_id', randomTrackID);
                formData.append('file', file?.blobFile);
                formData.append('fileKey', file?.fileKey);
                formData.append('emirates_id', true);
                formData.append('crmDestinationpath', 'volunteer')

                fileUploader(formData, (res) => {
                    file.isemirated = true;
                    file.isprocessed = true;
                    file.file_id = res.data.fileIds[0]
                    setLoading(false)
                    setEmiratesId(res.data.fileIds[0])
                })
            } else {
                setEmiratesId([])
            }
        }
        else {
            newFiles.forEach(file => {
                keys.push(file.fileKey)
                // if (!supportingFileKeys.includes(file.fileKey)) {
                // setSupportingFiles(s => [...s, file])
                setSupportingLoading(true)
                const formData = new FormData();

                formData.append('track_id', randomTrackID);
                formData.append('emirates_id', false);
                formData.append('fileKey', file?.fileKey);
                formData.append('crmDestinationpath', 'volunteer')


                formData.append('file', file.blobFile);


                fileUploader(formData, (res) => {
                    setSupportingLoading(false)
                    file.isemirated = false;
                    file.isprocessed = true;
                    file.file_id = res.data.fileIds[0]
                    // setUploadedFiles(files.map(file => {
                    //     return {
                    //         ...file,
                    //         id: file.id || res.data.webResponseArray[0][file.name]
                    //     }
                    // }))
                    // file.fileName = res.data[0]
                    // setVolunteerFiles([...volunteer_files, ...res.data.fileUrls])
                    // setIncidentFIles(res.data)
                    console.log(res.data.fileIds, 'res.data.fileIds');
                    setVolunteerFilesID([...volunteer_files_Id, ...res.data.fileIds]);
                })

            }
            )
            // supportingFileKeys.forEach(fileKey => {
            //     if (!keys.includes(fileKey)) {
            //         setSupportingFiles(s => s.filter(f => f.fileKey !== fileKey))
            //     }
            // }

        }



    };

    function submitHandler() {
        let errs = {}
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneNumberRegex = /^\+?\d{9,13}$/;
        if (emiratesID?.length === 0) { errs.emirates_id = t('Required Field') }
        if (!inputs.first_name?.trim()) { errs.first_name = t('Required Field') }
        if (!inputs.last_name?.trim()) { errs.last_name = t('Required Field') }
        if (!inputs.email?.trim()) {
            errs.email = t('Required Field');
        } else if (!emailRegex.test(inputs.email)) {
            errs.email = t('Invalid email address');
        }
        if (!inputs.phone_number?.trim()) {
            errs.phone_number = t('Required Field');
        } else if (!phoneNumberRegex.test(inputs.phone_number)) {
            errs.phone_number = t('Invalid Phone number');
        }
        if (!recaptchaVerified) { errs.captcha = t('Please check Captcha') }

        setErrors(errs)
        if (Object.keys(errs).length > 0) return
        setSubmitLoading(true)

        addVolunteer({
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            email: inputs.email,
            phone_number: inputs.phone_number,
            customer_contact_code: !flagImage?.code ? '+971' : flagImage?.code,
            eid: [emiratesID],
            // attachments: supportingFiles.map(f => f.fileName),
            attachments: volunteer_files_Id,
            track_id: randomTrackID,
            request_type: 'Website',
            udid: deviceId,
            ip: ipAddress,
            crmDestinationpath: 'volunteer'
        }, (res) => {
            if (res.status) {
                console.log(res.status)
                setSubmitLoading(false)

                setInputs({})
                setSuccess(true)

            } else {
                setSubmitLoading(false)
                setVolunteerShow(false)
            }
        })
    }

    useEffect(() => {
        getCountry((res) => {
            setCountries(res)
        })
    }, [])

    const handleCountry = (e) => {
        const code = e?.code
        const selected = countries.find(item => item.code === code);
        setFlagImage(selected)
    }

    return (
        <>
            {success ?
                (<div className="report__success">
                    <div className="icon"><img src="assets/img/icons/success.svg" alt="" /></div>
                    <h2>{t('Thank you Incident Submitted successfully')}</h2>
                    <p>{t('Your incident submission was successful')}. {t('Our team will now review and address your concerns promptly')}.</p>
                </div>) :
                (<div className='row vlnt-wusm'>

                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" value={inputs?.first_name || ''} placeholder={t('first_name') + '*'} className='form-control' onChange={(e) => changeHandler('first_name', e.target.value)} />
                            <small className='error'>{errors.first_name}</small>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" value={inputs?.last_name || ''} placeholder={t('last_name') + '*'} className='form-control' onChange={(e) => changeHandler('last_name', e.target.value)} />
                            <small className='error'>{errors.last_name}</small>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" value={inputs?.email || ''} placeholder={t('email') + '*'} className='form-control' onChange={(e) => changeHandler('email', e.target.value)} />
                            <small className='error'>{errors.email}</small>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form__number">
                            <div className="flag__grid">
                                <Select options={countries}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    className='countrySelect'
                                    getOptionLabel={(e) => e.code}
                                    getOptionValue={(e) => e?.code}
                                    defaultValue={+971}
                                    placeholder='+971'
                                    onChange={(e) => handleCountry(e)}

                                />
                                {
                                    flagImage?.flag ? <img src={FLAG_BASE_URL + 'flags/' + flagImage?.flag} className='flag' alt="" /> :
                                        <img src={'assets/img/icons/united_arab_emirates.svg'} className='flag' alt="" />
                                }

                                <input type="number" value={inputs?.phone_number || ''} placeholder={t('Phone Number') + '*'} className='form-control' onChange={(e) => changeHandler('phone_number', e.target.value)} />
                            </div>
                            <small className='error'>{errors.phone_number}</small>
                        </div>
                    </div>
                    <div className="col-md-12 position-relative mb-3">
                        <Uploader
                            listType="picture-text"
                            onChange={newFileList => handleChange(newFileList, 'emiratesId')}
                            draggable
                            autoUpload={false}
                            accept='application/pdf'
                            onRemove={file => setEmiratesId([])}
                            className='mb-0'
                            disabled={emiratesID?.length !== 0 ? true : false || loading}
                            shouldQueueUpdate={file => handlbeforeEmiratedID(file, 'emiratesId')}
                        >
                            <div className="fileUploader" style={{ minHeight: '50px', marginBottom: '5px' }}>
                                <img src="assets/img/icons/file-upload.svg" alt="" />
                                <p>{t('upload_emirates_id')}</p>
                            </div>
                        </Uploader>
                        {
                            emiratesID?.length == 0 && <small className='error'>{errors.emirates_id}</small>
                        }
                        <p className='error'>{errors.emiratesId}</p>
                        {loading && <div className="progress"></div>}
                    </div>
                    <div id="VolunteerCaptcha"></div>
                    <small className='error'>{errors.captcha}</small>
                    <div className="col-md-12 mt-3">
                        <p className='form-control-title'>{t('supporting_documents')} (PDF , JPG , PNG )</p>
                        {supportingLoading && <div className="progress"></div>}
                    </div>

                    <div className="col-md-12">
                        <Uploader
                            listType="picture"
                            onChange={newFileList => handleChange(newFileList, 'supportingFiles')}
                            onRemove={file => handleRemove(file)}
                            autoUpload={false}
                            accept='image/*, application/pdf'
                            disabled={volunteer_files_Id?.length < 3 ? false : true}
                            shouldQueueUpdate={file => handlbeforeUpload(file, 'supportingFiles')}
                        >

                            <div className="fileUploader" style={{ minHeight: '30px' }}>
                                <img src="assets/img/icons/add-more.svg" className='add-more' alt="" />
                            </div>


                        </Uploader>
                        <p className='error'>{errors.supportingFiles}</p>


                    </div>
                    <div className="text-center">
                        <button disabled={submitLoading} className='btn btn-primary' style={{ padding: '16px 30px' }} onClick={submitHandler}>{t('submit')} {submitLoading ? <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : <img src="assets/img/icons/arrow-right-red.svg" alt="" />}</button></div>



                </div>
                )
            }
        </>
    )
}
