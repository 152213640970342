import React, { useEffect, useState } from 'react'
import Title from '../components/Title/Title';
import { useTranslation } from 'react-i18next';
import useTranslate from '../components/useTranslate';
import { getQrDetails } from '../actions';
import MetaHead from '../components/MetaHead/MetaHead';
function Qrcode() {
    const { i18n } = useTranslation();
    const { t } = useTranslate();
     const [qrdetails, setQrdetails] = useState()
    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    useEffect(() => {
        getQrDetails((res) => {
            setQrdetails(res.data)
        })
    }, [])
    return (

        <>
          <MetaHead
    title={qrdetails?.qr_meta_title}
    desc={qrdetails?.qr_meta_description}
    keyword={qrdetails?.qr_meta_keyword}
  />
        
        
        <section className='alameen_qrcode'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="content sm-none">
                            <img src="assets/img/qr-code.png" data-aos="fade-up" data-aos-duration="1000" className='w-100 main' alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="content" data-aos="fade-up" data-aos-duration="2000">
                            <Title title={langSwitch ? qrdetails?.main_title : qrdetails?.main_title_ar}  />
                            <p dangerouslySetInnerHTML={{ __html: langSwitch ? qrdetails?.description : qrdetails?.description_ar }}></p>
                            <ul>
                                {qrdetails?.instagram && <li><a href={qrdetails?.instagram} target='_blank'><img src="/assets/img/icons/insta.svg" alt="" /></a></li> }
                                {qrdetails?.facebook && <li><a href={qrdetails?.facebook} target='_blank'><img src="/assets/img/icons/fb.svg" alt="" /></a></li>}
                                {qrdetails?.twitter &&<li><a href={qrdetails?.twitter} target='_blank'><img src="/assets/img/icons/xlogo.svg"  alt="" /></a></li>}
                                {qrdetails?.snapchat &&<li><a href={qrdetails?.snapchat} target='_blank'><img src="/assets/img/icons/snapchat.svg" alt="" /></a></li>}
                                {qrdetails?.linkedin &&<li><a href={qrdetails?.linkedin} target='_blank'><img src="/assets/img/icons/linkedin.svg" alt="" /></a></li>}
                                {qrdetails?.whatsapp &&<li><a href={`https://wa.me/${qrdetails?.whatsapp}`} target='_blank'><img src="/assets/img/icons/whatsapp.svg" alt="" /></a></li>}
                                {qrdetails?.weblink &&<li><a href={qrdetails?.weblink} target='_blank'><img src="/assets/img/icons/web.svg" alt="" /></a></li>}
                                {qrdetails?.playstore &&<li><a href={qrdetails?.playstore} target='_blank'><img src="/assets/img/icons/plays.svg" alt="" /></a></li>}
                                {qrdetails?.appstore &&<li><a href={qrdetails?.appstore} target='_blank'><img src="/assets/img/icons/apps.svg" alt="" /></a></li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Qrcode