import React, { useEffect, useState } from 'react'
import Title from '../Title/Title'
import { Link } from 'react-router-dom'
import { getEvents } from '../../actions'
import { useTranslation } from 'react-i18next';
import useTranslate from '../useTranslate';
import { API_IMAGE_URL } from '../../configuration';
import moment from 'moment';
import { generateSlug } from '../../utils/Utils';
export default function EventRecent({id}) {
    const { i18n } = useTranslation();
    const { t } = useTranslate();

    const [events, setEvents] = useState([])
    const filteredData = events.filter(item => !id.includes(item._id));
    const recentEvent = filteredData?.slice(0, 2)

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('perPage', 10)
        formdata.append('page', 0)
        getEvents(formdata, (res) => {
            setEvents(res.data)
        })
    }, [])


    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    return (
        <div className='recent__posts'>
            <Title title={t('Recent Events')} fontWeight={500} fontSize={20} />
            <section className="latest_updates pt-3 pb-0">
                <div className="container">
                    <div className="row">
                        {
                            recentEvent.map((ele, i) => {
                                return (
                                    <div className="col-md-6" key={i}>
                                        <Link to={`/updates/event-details/${ele?._id}/${generateSlug(ele?.title)}`}>
                                            <div className="newsEventGrid">
                                                <div className="tag">{t('Events')}</div>
                                                <img src={API_IMAGE_URL + ele?.file_path + ele?.image} alt="" />
                                                <div className="content">
                                                    <h3>{langSwitch ? ele?.title : ele?.title_ar}</h3>
                                                    <h6><span>{moment(ele?.event_date).format('MMMM DD, yyyy')}</span> | <span>{moment(ele?.event_date).format('hh : mm A')}</span> | <span>{ele?.location}</span></h6>
                                                    <p dangerouslySetInnerHTML={{ __html: langSwitch ? ele.content : ele.content_ar}}></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </div>
    )
}
