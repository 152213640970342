import axios from "axios";
import { API_BASE_URL, API_INSTA_TOKEN, CRM_API_BASE_URL,API_BASE_URL_MEDIA_SERVER} from "./configuration";
import { toast } from "react-toastify";
import axiosInstance from "./utils/axiosInstance";


export const homeGetter = (cb) => {
    axios.get(API_BASE_URL + 'api/v1/home').then(res => {
        cb && cb(res.data)
    })
}

export const latestUpdates = (data, cb) => {
    axios.post(API_BASE_URL + 'api/v1/get-updates', data).then(res => {
        cb && cb(res.data)
    })
}

export const aboutUs = (cb) => {
    axios.get(API_BASE_URL + 'api/v1/aboutus').then(res => {
        cb && cb(res.data.data)
    })
}

export const getEvents = (data, cb) => {
    axios.post(API_BASE_URL + 'api/v1/get-events', data).then(res => {
        cb && cb(res.data)
    })
}

export const getNews = (data, cb) => {
    axios.post(API_BASE_URL + 'api/v1/get-news', data).then(res => {
        cb && cb(res.data)
    })
}
export const eventDetails = (id, cb) => {
    axios.get(API_BASE_URL + `api/v1/get-events/${id}`).then(res => {
        cb && cb(res.data)
    })
}

export const newsDetails = (id, cb) => {
    axios.get(API_BASE_URL + `api/v1/get-news/${id}`).then(res => {
        cb && cb(res.data)
    })
}

export const getTerms = (cb) => {
    axios.get(API_BASE_URL + `api/v1/terms-condition`).then(res => {
        cb && cb(res.data)
    })
}
export const getPrivacy = (cb) => {
    axios.get(API_BASE_URL + `api/v1/privacy-policy`).then(res => {
        cb && cb(res.data)
    })
}
export const getQrDetails = (cb) => {
    axios.get(API_BASE_URL + `api/v1/qrcode`).then(res => {
        cb && cb(res.data)
    })
}
export const getGeneralData = (cb) => {
    axios.get(API_BASE_URL + `api/v1/get-general-data`).then(res => {
        cb && cb(res.data)
    })
}


// CRM ====>

export const getCountry = (cb) => {
    axios.get(API_BASE_URL + 'getCountryFlag').then(res => {
        cb && cb(res.data.data)
    })
}


export const addLectureRequest = (data, cb) => {
    axiosInstance.post(API_BASE_URL + 'addLectureRequest', data).then(res => {
        cb && cb(res.data)
    }).catch((err) => {
        toast.error("Something went wrong!")
        cb(err.response.data)
    })
}

export const reportIncident = (data, cb) => {
    axiosInstance.post(API_BASE_URL + 'addRequest', data).then(res => {
        cb && cb(res.data)
    }).catch((err) => {
        toast.error("Something went wrong!")
        cb(err.response.data)
    })
}

export const getIncidentTypes = (cb) => {
    axios.get(API_BASE_URL + 'getIncidentTypeList').then(res => {
        cb && cb(res.data)
    })
}

export const addVolunteer = (data, cb) => {
    axiosInstance.post(API_BASE_URL + 'insertVolunteer', data).then(res => {
        cb && cb(res.data)
        
    }).catch((err) => {
        console.log(err)
        toast.error(err?.response?.data?.message)
        cb(err)

    })
}

export const fileUploader = (formdata, cb) => {
axios.post(API_BASE_URL_MEDIA_SERVER + 'file/uploadFile', formdata).then(res => {
        // axiosInstance.post(API_BASE_URL + 'multiFileUpload', formdata).then(res => {
        cb && cb(res.data)
        if (res.data.status) {
            // toast.success(res.data.message)
        } else toast.error(res.data.message)

    })
}

export const userLogin = (formdata, cb) => {
    axiosInstance.post(API_BASE_URL + 'authenticateWithSocialMedia', formdata).then(res => {
        cb && cb(res.data)
        if (res.data.status) toast.success("Login Successfully")
        else toast.error(res.data.message)
    })
}

export const getReports = (data, cb) => {
    axiosInstance.post(API_BASE_URL + `api/v1/incidentRequestList`, data).then(res => {
        cb && cb(res.data)
    }).catch((err) => {
        cb(err.response.data)

    })
}

export const getInstaFeed = (instaToken, cb) => {
    console.log(instaToken)
    axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&limit=5&access_token=${instaToken}`).then(res => {
        cb && cb(res.data.data)
    })
}
export const getSecurity = (cb) => {
    axios.get(API_BASE_URL + `api/v1/security`).then(res => {
        cb && cb(res.data.security_data)
    })
}

// GET TOEKN

export const getToken = (data, cb) => {
    axios.post(API_BASE_URL + `api/v1/tokenGeneration`, data).then(res => {
        cb && cb(res.data.data)
    })
}


export const getIPaddress = async (cb) => {
    await axios.get('https://api.ipify.org?format=json').then(res => {
        if (!res?.data?.hasOwnProperty('error')) {
            cb && cb(res.data)
        }
    }).catch(async (er) => {
        await axios.get('https://ipinfo.io/json').then(data => {
            cb && cb(data.data)
        })
    })
}