import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import "./i18n";
import { LoadScript } from '@react-google-maps/api';
import { HelmetProvider } from 'react-helmet-async';
const libraries = ['places'];
const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <GoogleOAuthProvider clientId="23315131538-c25hbv9hm41n450lh8ofsf3i482oppv7.apps.googleusercontent.com"> */}
      <LoadScript loadingElement={'.'} googleMapsApiKey="AIzaSyB0Nlsa-4pUfxW7p7NXYYaYBffh1VbrpFM" libraries={libraries}>
      <HelmetProvider context={helmetContext}>
        <App />
      </HelmetProvider>
      </LoadScript>
      {/* </GoogleOAuthProvider> */}
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
