import React, { useEffect, useState } from 'react'
import Title from '../components/Title/Title'
import ReportIncident from '../components/Modal/ReportIncident'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { generalDataGetter, getInstaFeed, getSecurity, homeGetter, latestUpdates } from '../actions';
import { API_BASE_URL, API_IMAGE_URL } from '../configuration';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useTranslate from '../components/useTranslate';
import { generateSlug } from '../utils/Utils';
import VolunteerWithUs from '../components/Modal/VolunteerWithUs';
import MetaHead from '../components/MetaHead/MetaHead';
export default function Home() {
  const [show, setShow] = useState(false);
  const [volunteerShow, setVolunteerShow] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleVolunteerClose = () => setVolunteerShow(false);
  const handleVolunteerShow = () => setVolunteerShow(true);

  const [instaFeeds, setInstaFeeds] = useState([])
  const [banners, setBanners] = useState()
  const [videoUrl, setvideoUrl] = useState('')
  const [general, setGeneral] = useState()
  const [updates, setUpdates] = useState()

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    getSecurity((res)=>{
      const tokenObject = res.find(token => token.title === "insta_token");
      getInstaFeed(tokenObject?.key, (res) => {
        setInstaFeeds(res)
      })
    })
  
  }, [])
  

  useEffect(() => {
    homeGetter((res) => {
      setBanners(res.details)
      setGeneral(res.general)
    })


    const formdata = new FormData()
    formdata.append('perPage', 4)
    formdata.append('page', 0)
    latestUpdates(formdata, (res) => {
      setUpdates(res.data)
    })
  }, [])



  const [bgClass, setBgClass] = useState('bg-color-1');

  // Function to toggle the background color class
  const changeBgClass = () => {
    setBgClass(prevClass => (prevClass === 'bg-color-1' ? 'bg-color-2' : 'bg-color-1'));
  };


  useEffect(() => {
    if (typeof banners?.banner_image != undefined) {
      setvideoUrl(API_IMAGE_URL + banners?.file_path + banners?.banner_image)

    }
  }, [banners?.banner_image])

  return (

    <>
      <MetaHead
        title={banners?.home_meta_title}
        desc={banners?.home_meta_description}
        keyword={banners?.home_meta_keyword}
      />
      <section className='alameen__home'>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="main__banner">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className={`banner-h1 ${bgClass} bg-span`}
                  dangerouslySetInnerHTML={{ __html: langSwitch ? banners?.banner_title : banners?.banner_title_ar }} >
                </h1>

                <p data-aos="fade-up" data-aos-duration="2500" dangerouslySetInnerHTML={{ __html: langSwitch ? banners?.banner_desc : banners?.banner_desc_ar }} />
                <button className='btn btn-primary' data-aos="fade-up" data-aos-duration="3000" onClick={handleShow}>{t('Report Incident')} <img src="assets/img/icons/arrow-right-red.svg" alt="" /></button>
              </div>
            </div>
          </div>
          <div className="row align-items-end l-vh-100 sm-vh-auto tab-reverse">
            <div className="col-md-5 col-tab-12">
              <div className="alameen__about">
                <img src="assets/img/dark-about.svg" className='w-100 about about-dark' alt="" />
                <img src="assets/img/light-about.svg" className='w-100 about about-light' alt="" />
              </div>
            </div>
            <div className="col-md-6 col-tab-12">
              <div className="alameen__about">
                <h2 data-aos="fade-in">{langSwitch ? banners?.main_title : banners?.main_title_ar}</h2>
                <p data-aos="fade-in" dangerouslySetInnerHTML={{ __html: langSwitch ? banners?.description : banners?.description_ar }} />
                <Link to='/about'><button className='btn btn-primary'>{t('Know More')} <img src="assets/img/icons/arrow-right-red.svg" alt="" /></button></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hand__mobile" data-aos="fade-left">
          <img src="assets/img/mobile.png" alt="" />
          <div className="mask">
            <video muted autoPlay playsInline loop className="masked-video" src={API_IMAGE_URL + banners?.file_path + banners?.banner_image} poster="assets/img/poster.jpg" />
          </div>
        </div>

      </section>
      <section className="volunteer__withUs">

        <div className="container">
          <div className="volunteerGrid">
            <div className="row">
              <div className="col-md-6 col-tab-12">
                <div className="content" data-aos="fade-up">
                  <Title title={langSwitch ? banners?.volunteer_title_en : banners?.volunteer_title_ar} />
                  {/* <h2>{langSwitch ? banners?.volunteer_title_en : banners?.volunteer_title_ar}</h2> */}
                  <p dangerouslySetInnerHTML={{ __html: langSwitch ? banners?.volunteer_description : banners?.volunteer_description_ar }} />
                  <button onClick={handleVolunteerShow} className='btn btn-primary'>{t('Apply Now')}<img src="assets/img/icons/arrow-right-red.svg" alt="" /></button>
                </div>
              </div>
              <div className="col-md-6 col-tab-12">
                <div className="image">
                  <img src={API_IMAGE_URL + banners?.file_path + banners?.volunteer_icon} className='w-100 main' alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="latest_updates">
        <div className="container">
          <Title title={t('Latest Updates')} />
          <div className="row">
            {
              updates?.map((ele, i) => {
                return (
                  <div className="col-md-6" key={i}>
                    <Link to={`/updates/${ele?.type}-details/${ele?._id}/${generateSlug(ele?.title)}`}>
                      <div className="newsEventGrid">
                        <div className="tag">{ele?.type == 'news' ? t("News") : t("Events")}</div>
                        <img src={API_IMAGE_URL + ele?.file_path + ele?.image} alt="" />
                        <div className="content">
                          <h3>{langSwitch ? ele?.title : ele?.title_ar}</h3>
                          {
                            ele?.type == 'news' ? <h6><span>{moment(ele?.news_date).format('MMMM DD, yyyy')}</span> | <span>{ele?.location_en}</span></h6> :
                              <h6><span>{moment(ele?.event_date).format('MMMM DD, yyyy')}</span> | <span>{moment(ele?.event_date).format('hh : mm A')}</span> | <span>{ele?.location}</span></h6>
                          }

                          <p dangerouslySetInnerHTML={{ __html: langSwitch ? ele?.content : ele?.content_ar }} />
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })
            }

          </div>
          <div className="text-center mt-4"> <Link to='/updates'><button className='btn btn-primary'>{t('View More')} <img src="assets/img/icons/arrow-right-red.svg" alt="" /></button></Link></div>
        </div>
      </section>
      <section className="app__download">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="appUi">
                <Title title={langSwitch ? banners?.download_title_en : banners?.download_title_ar} fontSize={45} />
                <p data-aos="fade-in" dangerouslySetInnerHTML={{ __html: langSwitch ? banners?.download_description : banners?.download_description_ar }}></p>
                <div className="download__logos" data-aos="fade-in">
                  {banners?.playstore && <a href={banners?.playstore} target='_blank'><img className='play' src="assets/img/icons/googleply.svg" alt="" /></a>}
                  {banners?.appstore && <a href={banners?.appstore} target='_blank'><img className='appstore' src="assets/img/icons/appstore.svg" alt="" /></a>}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="app__ui">
                <img src={API_IMAGE_URL + banners?.file_path + banners?.download_icon} className='w-100' alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        instaFeeds?.length > 0 &&

        <section className="instagram__feed">
          <div className="container-fluid">
            <div className="text-center">  <Title title={t('Instagram')} /></div>
            <div className="row row-cols-5 sm-row-cols-2 justify-content-center">
              {
                instaFeeds?.map((ele, i) => {
                  return (
                    <div className="col p-1" key={i}>
                      <a href={ele?.permalink} target="_blank">
                        <div className="instaFeed">
                          <div className="icon"><img src="/assets/img/icons/insta.svg" alt="" /></div>
                          <img src={ele?.thumbnail_url ?? ele.media_url} className='w-100' alt="" />
                          {
                            ele?.caption && <div className="caption"><h4>{ele?.caption}</h4></div>
                          }
                          
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </section>
      }

      {/* Report Incident Modal */}
      <Modal show={show} backdrop="static" size="lg" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <img src="/assets/img/icons/report-incident.svg" alt="" /> {t('Submit Incident')}
          </Modal.Title>
        </Modal.Header>
        <ReportIncident setShow={setShow} />
      </Modal>

      {/* Volunteer With Us Modal */}
      <Modal show={volunteerShow} backdrop="static" size="lg" centered onHide={handleVolunteerClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('Volunteer With Us')} <img src="/assets/img/icons/arrow-up-red.svg" alt="" />
          </Modal.Title>
        </Modal.Header>
        <VolunteerWithUs setVolunteerShow={setVolunteerShow} />
      </Modal>
    </>
  )
}
