import React from 'react'
import './Title.scss'
function Title({title,fontSize,fontWeight}) {
  return (
    <div className='subTitle'>
        <h2 style={{fontSize: fontSize,fontWeight:fontWeight}}>{title}</h2>
    </div>
  )
}

export default Title