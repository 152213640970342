import React, { useEffect, useState } from 'react'
import { getTerms } from '../actions';
import { useTranslation } from 'react-i18next';
import useTranslate from '../components/useTranslate';
import Title from '../components/Title/Title';
import MetaHead from '../components/MetaHead/MetaHead';

export default function Terms() {
    const [terms, setTerms] = useState()
    const { i18n } = useTranslation();
    const { t } = useTranslate();
    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    useEffect(() => {
        getTerms((res) => {
            setTerms(res.data)
        })
    }, [])
    return (

        <>
             <MetaHead
    title={terms?.meta_title}
    desc={terms?.meta_description}
    keyword={terms?.meta_keyword}
  />
   <section className='termsConditions'>
            <div className="container">
                <Title title={langSwitch ? terms?.title : terms?.title_ar } />
                <p data-aos="fade-in" dangerouslySetInnerHTML={{ __html: langSwitch ? terms?.description : terms?.description_ar}}></p>
            </div>
        </section>
        </>
       
    )
}
