import axios from 'axios'; 
import { API_BASE_URL } from '../configuration';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your API URL
});
const storeToken = (newAccessToken,csrfToken='') => {
    localStorage.setItem("token", newAccessToken);
    localStorage.setItem("csrfToken", csrfToken);
  };
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token")
    if (token) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    config.headers["x-csrf-token"] = `${localStorage.getItem("csrfToken")}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken")
        const response = await axios.post(`${API_BASE_URL}api/v1/verifyRefresh`,{ refreshToken:refreshToken,device_id:localStorage.getItem("device_id") });
        const { accessToken, csrfToken } = response.data.data;
        storeToken(accessToken,csrfToken);
     
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        axios.defaults.headers.common["x-csrf-token"]=`${csrfToken}`
        return axiosInstance(originalRequest);
      } catch (err) { 
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;