import axios from "axios";


export function generateSlug(title) {
  return title
    ?.toLowerCase()
    ?.replace(/[^a-z0-9]+/g, '-')
    ?.replace(/(^-|-$)+/g, '');
}



