import React, { useEffect, useState } from 'react'
import Title from '../Title/Title'
import { Link } from 'react-router-dom'
import { getNews } from '../../actions';
import { API_IMAGE_URL } from '../../configuration';
import { useTranslation } from 'react-i18next';
import useTranslate from '../useTranslate';
import moment from 'moment';
import { generateSlug } from '../../utils/Utils';
export default function NewsRecent({id}) {
    const { i18n } = useTranslation();
    const { t } = useTranslate();

    const [news, setNews] = useState([])
    const filteredData = news.filter(item => !id.includes(item._id));
    const recentNews = filteredData?.slice(0, 2)

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('perPage', 10)
        formdata.append('page', 0)
        getNews(formdata, (res) => {
            setNews(res.data)
        })
    }, [])



    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    return (
        <div className='recent__posts'>
            <Title title={t('Recent News')} fontWeight={500} fontSize={20} />
            <section className="latest_updates pt-3 pb-0">
                <div className="container">
                    <div className="row">
                        {
                            recentNews.map((ele, i) => {
                                return (
                                    <div className="col-md-6" key={i}>
                                        <Link to={`/updates/news-details/${ele?._id}/${generateSlug(ele?.title)}`}>
                                            <div className="newsEventGrid">
                                                <div className="tag">{t('News')}</div>
                                                <img src={API_IMAGE_URL + ele?.file_path + ele?.image} alt="" />
                                                <div className="content">
                                                    <h3>{langSwitch ? ele?.title : ele?.title_ar}</h3>
                                                    <h6><span>{moment(ele?.news_date).format('MMMM DD, yyyy')}</span> | <span>{ele?.location_en}</span></h6>
                                                    <p dangerouslySetInnerHTML={{ __html: langSwitch ? ele.content : ele.content_ar}}></p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </div>
    )
}
