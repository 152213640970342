import React, { useEffect, useState } from 'react'
import Title from '../components/Title/Title'
import { Link } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import News from '../components/Updates/News';
import Events from '../components/Updates/Events';
import { getEvents, getNews, latestUpdates } from '../actions';
import { API_IMAGE_URL } from '../configuration';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useTranslate from '../components/useTranslate';
import { generateSlug } from '../utils/Utils';
import ImageComponent from '../components/ImageComponent/ImageComponent';
import MetaHead from '../components/MetaHead/MetaHead';
export default function Updates() {
    const { i18n } = useTranslation();
    const { t } = useTranslate();
    const [key, setKey] = useState('News');
    const [updates, setUpdates] = useState()
    const [features, setFeatures] = useState({})

    // NEWS AND EVENTS COUNT
    const [newsCount, setNewsCount] = useState()
    const [eventCount, setEventsCount] = useState()

    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('perPage', 10)
        formdata.append('page', 0)
        latestUpdates(formdata, (res) => {
            setUpdates(res.data)
            setFeatures(res.latestUpdates)
        })
    }, [])




    return (
        <>
          <MetaHead
    title={features?.title}
    desc={features?.content}
  />
            <section className="latest__updates">
                <div className="container">
                    <Title title={t('Latest Updates')} />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="update__Grid" data-aos="fade-up" data-aos-duration="2000">
                                <div className="tag">{features?.type=='news'? t('News'): t('Events')}</div>
                                <ImageComponent className={'main'} src={`${API_IMAGE_URL}${features?.file_path}${features?.image}`}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="update__Grid" data-aos="fade-up" data-aos-duration="3000">
                                <div className="content">
                                    <Link to={`/updates/${features?.type}-details/${features?._id}/${generateSlug(features?.title)}`}><h2>{langSwitch ? features?.title : features?.title_ar}</h2></Link>
                                    {
                                        features?.type == 'event' ? <h6><span>{moment(features?.event_date).format('MMMM DD, yyyy')}</span> | <span>{moment(features?.event_date).format('hh : mm A')}</span> | <span>{langSwitch ? features?.location : features?.location_ar}</span></h6> :
                                        <h6><span>{moment(features?.news_date).format('MMMM DD, yyyy')}</span> | <span>{langSwitch ? features?.location_en : features?.location_ar}</span></h6>
                                    }
                                    
                                    <p dangerouslySetInnerHTML={{ __html: langSwitch ? features?.content : features?.content_ar }}></p>
                                    <Link to={`/updates/${features?.type}-details/${features?._id}/${generateSlug(features?.title)}`}>
                                        <button className='btn btn-primary'>{t('Read More')} <img src="assets/img/icons/arrow-right-red.svg" alt="" /></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 rtl-direction"
                >
                    
                      <Tab eventKey="News" title={t('News') + ` (${newsCount})`} >
                        <News setNewsCount={(e)=>setNewsCount(e)} />
                      </Tab> 
                    
                   
                    <Tab eventKey="Events" title={t('Events') + ` (${eventCount})`}>
                        <Events setEventsCount={(e)=>setEventsCount(e)} />
                    </Tab>


                </Tabs>
            </div>
        </>
    )
}
