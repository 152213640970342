import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import RouteHandler from './router/router';
import ScrollTop from './components/ScrollTop';
import './default.scss';
import './pages/pageStyles.scss';
import { useEffect, useState } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";
import './dark.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './rtl.scss'
import './responsive.scss';
function App() {
  const [loading, setLoading] = useState(true)
  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);
  return (
      <>

        <ScrollTop />
        <Header />
        <RouteHandler />
        <Footer />
        <ToastContainer autoClose={3000} position='bottom-center' />
      </>
  );
}

export default App;
