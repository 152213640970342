import React, { useEffect, useRef, useState } from 'react'
import './Footer.scss'
import { Link, NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import VolunteerWithUs from '../Modal/VolunteerWithUs';
import useTranslate from '../useTranslate';
import { useTranslation } from 'react-i18next';
import { getGeneralData, getIPaddress, getToken } from '../../actions';
import { v4 as uuidv4 } from 'uuid';

export default function Footer() {
  const { t } = useTranslate()
  const { i18n } = useTranslation()
  const [details, setDetails] = useState()
  const [ip, setIp] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [user, setUser] = useState()


  // LANGUAGE
  const langSwitch = i18n?.language === 'en'
  const defaultLang = localStorage.getItem('localLang')
  const storedUser = localStorage.getItem('user');
  let device_id = localStorage.getItem('device_id')
  let ipAddress = localStorage.getItem('ip')

  useEffect(() => {
    if (i18n?.language == 'ar' || defaultLang == 'en-US') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [langSwitch])


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const d = new Date();
  let year = d.getFullYear();
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }


  useEffect(() => {
    setUser(storedUser ? JSON.parse(storedUser) : {});
    let deviceId = localStorage.getItem('device_id');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('device_id', deviceId);
    }
    setDeviceId(deviceId);
  }, []);


  useEffect(() => {
    if (!ipAddress) {
      getIPaddress((res) => {
        setIp(res.ip)
        localStorage.setItem('ip', res.ip);
      })
    }
  }, [])




  useEffect(() => {
    device_id = localStorage.getItem('device_id')
    ipAddress = localStorage.getItem('ip')
    // debugger;
    const i_data = {
      ip_addr: ipAddress || ip,
      device_id: device_id,
      email: user?.email ? user?.email : '',
    }
    getToken(i_data, (res) => {
      const token = res?.token?.accessToken;
      const csrfToken = res?.token?.csrfToken;
      const refreshToken = res?.token?.refreshToken;
      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('csrfToken', csrfToken);
        localStorage.setItem('refreshToken', refreshToken);
      }
    });
  }, []);

  useEffect(() => {
    getGeneralData((res) => {
      setDetails(res.data)
    })
  }, [])


  return (
    <>
      <div className='alameen__footer'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 p-0 col-tab-6">
              <div className="footer__widgets">
                <ul>
                  <li><NavLink to='/'>{t('Home')}</NavLink></li>
                  <li><NavLink to='/about'>{t('About')}</NavLink></li>
                  <li><NavLink to='/updates'>{t('Updates')}</NavLink></li>
                  <li><NavLink to='/contact'>{t('Contact')}</NavLink></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 p-0 col-tab-6">
              <div className="footer__widgets">
                <div className="contact">
                  <div className="grid" onClick={handleShow}>
                    <p>{t('Volunteer With Us')} <img src="/assets/img/icons/arrow-up-red.svg" alt="" /></p>
                  </div>
                  <div className="grid">
                    <p>{t('Call Us')}<img src="/assets/img/icons/arrow-up-red.svg" alt="" /></p>
                    <h4><a href={`tel:${details?.floating_contact_number}`}>{details?.floating_contact_number}</a></h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-0 col-tab-6">
              <div className="footer__widgets">
                <img src="/assets/img/logo.svg" alt="" />
              </div>
            </div>
            <div className="col-md-4 p-0 col-tab-6">
              <div className="footer__widgets">
                <img src="/assets/img/government-of-dubai.svg" className='gov_logo' alt="" />
              </div>
            </div>
            <div className="col-md-4 p-0 col-tab-12">
              <div className="footer__widgets social__links">
                <ul>
                  {details?.instagram && <li><a href={details?.instagram} target='_blank'><img src="/assets/img/icons/insta.svg" alt="" /></a></li>}
                  {details?.facebook && <li><a href={details?.facebook} target='_blank'><img src="/assets/img/icons/fb.svg" alt="" /></a></li>}
                  {details?.linkedin && <li><a href={details?.linkedin} target='_blank'><img src="/assets/img/icons/linkedin.svg" style={{ height: '20px' }} alt="" /></a></li>}
                  {details?.twitter && <li><a href={details?.twitter} target='_blank'><img src="/assets/img/icons/x.svg" style={{ height: '20px' }} alt="" /></a></li>}
                  {details?.snapchat && <li><a href={details?.snapchat} target='_blank'><img src="/assets/img/icons/snapchat.svg" style={{ height: '20px' }} alt="" /></a></li>}
                  {details?.whatsapp && <li><a href={`https://wa.me/${details?.whatsapp}`} target='_blank'><img src="/assets/img/icons/whatsapp.svg" style={{ height: '23px' }} alt="" /></a></li>}
                </ul>
              </div>
            </div>
            <div className="col-md-4 p-0 col-tab-12">
              <div className="footer__widgets social__links termsConditions">
                <p>{t('copyright')} © {year} {t('rights reserved')}</p>
                <div className="terms">
                  <ul>
                    <li><Link to='terms'>{t('terms')}</Link></li>
                    <li><Link to='privacy'>{t('privacy')}</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__img">
          <img src="/assets/img/footer.svg" alt="" />
        </div>


      </div>
      <button className='btn topScroll' onClick={scrollToTop}><img src="/assets/img/icons/arrow-up.svg" alt="" /></button>
      {/* Volunteer With Us Modal */}
      <Modal show={show} backdrop="static" size="lg" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <>{t('Volunteer With Us')} <img src="/assets/img/icons/arrow-up-red.svg" alt="" /></>
          </Modal.Title>
        </Modal.Header>
        <VolunteerWithUs setShow={setShow} />
      </Modal>

    </>
  )
}
