import React, { useEffect, useState } from 'react'
import './noData.scss'
import { useNavigate } from 'react-router-dom'
import useTranslate from '../useTranslate';
import { useTranslation } from 'react-i18next';

export default function NoData() {
    const { i18n } = useTranslation();
    const { t } = useTranslate();
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1)
    }

    // LANGUAGE
    const [lang, setLang] = useState("");
    const langSwitch = i18n?.language === "en";
    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);
    return (

        <section className='noData'
            data-aos="fade-in"
            data-aos-duration="1000">
            <div className="container">
                <div className="content">
                    {/* <img className='main' src="assets/img/no-data.svg" alt="" /> */}
                    <h4>{t('No data found')}</h4>
                    <p>{t('There are no data to show here right now')}.</p>
                    <button className='btn btn-primary' onClick={handleBack}>{t('Go Back')} <img src="assets/img/icons/arrow-right-red.svg" alt="" /> </button>
                </div>
            </div>
        </section>
    )
}
