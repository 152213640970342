import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import About from '../pages/About'
import Updates from '../pages/Updates'
import NewsDetails from '../pages/NewsDetails'
import Contact from '../pages/Contact'
import EventDetails from '../pages/EventDetails'
import Reports from '../pages/Reports'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import QrCode from '../pages/Qrcode'

function RouteHandler() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/updates" element={<Updates />} />
      <Route path="/updates/news-details/:id/:slug" element={<NewsDetails />} />
      <Route path="/updates/event-details/:id/:slug" element={<EventDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/qrcode" element={<QrCode />} />
    </Routes>
  )
}

export default RouteHandler